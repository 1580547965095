<template>
  <Layout>
    <WithLocation :id="locationId">
      <div slot-scope="{ location, metaFields, isLocationLoading }">
        <BaseSpinner v-if="isLocationLoading" />
        <template v-else>
          <BaseBreadcrumbs
            :breadcrumbs="[
              { link: '/locations', title: 'Locations' },
              { link: '', title: location.JOB_NUMBER__IDENTIFIER },
            ]"
          />
          <BaseLocationMeta :fields="metaFields" />
        </template>
      </div>
    </WithLocation>

    <h3 class="py-2">Daily Reports</h3>

    <WithReports :items-per-page="9" :filters="filterByLocation">
      <div
        slot-scope="{
          reports,
          areReportsLoading,
          searchReports,
          clearReportsSearch,
          totalPages,
          totalRecords,
          page,
          itemsPerPage,
        }"
      >
        <transition name="fade-fast" mode="out-in" appear>
          <BaseSpinner v-if="areReportsLoading" />
          <ReportsList
            v-else-if="reports && reports.length"
            :reports="reports"
          />
          <p v-else-if="$route.query.s && !reports.length"
            >No reports matched the search "{{ $route.query.s }}".</p
          >
          <p v-else>No reports found for this location.</p>
        </transition>
        <BasePagination
          v-if="!areReportsLoading"
          :page="page"
          :total-pages="totalPages"
          :number-of-records="totalRecords"
          :items-per-page="itemsPerPage"
          record-type="Reports"
          @nextPage="nextPage"
          @previousPage="previousPage"
        />
      </div>
    </WithReports>
  </Layout>
</template>

<script>
import * as appConfig from '@src/app.config'
import Layout from '@layouts/breadcrumbLayout'
import WithReports from '@dataProviders/WithReports'
import WithLocation from '@dataProviders/WithLocation'

import ReportsList from '@components/ReportsListView/ReportsList'

import { HumanFields } from '@constants/knack'

import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'LocationView',
  components: {
    Layout,
    WithReports,
    WithLocation,
    ReportsList,
  },
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [{ name: 'description', content: appConfig.description }],
    }
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getLocationFromId']),
    ...routeState(['query']),
    filterByLocation() {
      return [
        {
          field: HumanFields.DAILY_REPORT.LOCATION,
          operator: 'is',
          value: this.locationId,
        },
      ]
    }, // filterByLocation
    metaTitle() {
      return (
        this.getLocationFromId(this.locationId)?.JOB_NUMBER__IDENTIFIER ?? ''
      )
    },
  },
  methods: {
    nextPage() {
      let pageNum = +this.query.page ? +this.query.page + 1 : 2
      this.$router.push({
        query: { ...this.$route.query, page: pageNum },
      })
    }, // nextPage
    previousPage() {
      let pageNum = +this.query.page
      pageNum = pageNum && pageNum > 1 ? pageNum - 1 : 1
      this.$router.push({
        query: { ...this.$route.query, page: pageNum },
      })
    }, // previousPage
  },
}
</script>
